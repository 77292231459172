__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import { onLoading } from "./_modules/website";
import { loadPage } from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

// Shop Functions
import {Cart} from "./_modules/cart";
import {Checkout} from "./_modules/templates/checkout";
import {Product} from "./_modules/templates/product";
import {ProductVariants} from "./_modules/templates/product-variants";
import {Shop} from "./_modules/templates/shop";

import "./_modules/components/menu";
import "./_modules/components/events";

import {ConditionalFields} from "./_modules/templates/conditional-fields";
import {Select} from "./_modules/components/select";
import {collapse} from "./_modules/components/collapse";


import SlimSelect from 'slim-select';

// Site Functions
import {Carousel} from "./_modules/components/swiper";


document.addEventListener("DOMContentLoaded", () => {
  onLoading();
  loadPage();
  ConditionalFields();
  Select();
  collapse();
  Carousel();

});
