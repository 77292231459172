// Corresponding Class for site/templates/product-variant.php

import {Carousel, CarouselProduct} from "../components/swiper";

export class ProductVariants {
  constructor(element) {
    const selectElement = element.querySelector('select[data-action="update-variant"]');
    const customSelect = element.querySelector('.custom-select.select-variant');
    const priceElement = element.querySelector('.price');
    const galleryElement = element.querySelector('.gallery .swiper-wrapper');
    const slotStockInfoElement = element.querySelector('.price div[data-slot="stockInfo"]');

    function updateProductVariant() {
      const selectedOption = selectElement.options[selectElement.selectedIndex];
      element.scrollTop = 0;

      if (selectedOption) {
        // update element’s “id”. This id is used to add the product to the cart (see product.js).
        element.setAttribute('data-id', selectElement.value);
        console.log('update');

        // update price and stock info and image
        priceElement.innerHTML = selectedOption.dataset.price + "<div data-slot='stockInfo' class='color-black'>" + selectedOption.dataset.stockInfo + "</div>";

        /*galleryElement.innerHTML = selectedOption.dataset.image;*/

        // update url the get a unique uri for each product variant
        window.location.hash = `#${selectedOption.dataset.uid}`;

        const Url = "/"+selectElement.value + ".json";
        galleryElement.style.opacity = "0";
        // Fetch Carousel
        fetch(Url).then(function (response) {
          return response.json();
        }).then(function (content) {
          galleryElement.innerHTML = content;
          CarouselProduct();
          galleryElement.style.opacity = "1";
        });




      }
    }

    function useHashToUpdateProductVariant() {
      // Use “substr(1)” to remove first “#”
      const hash = window.location.hash.substr(1);

      // update select element if url hash a hash value
      if (hash) {
        selectElement.value = `${element.dataset.parentId}/${hash}`;
        updateProductVariant();
      }
    }

    /*selectElement.addEventListener('change', updateProductVariant);*/
    customSelect.addEventListener("click", () => {
      updateProductVariant();
    });

/*
    window.addEventListener('hashchange', useHashToUpdateProductVariant);
*/

    useHashToUpdateProductVariant();
  }
}

document.querySelectorAll('.product-variants').forEach((productElement) => new ProductVariants(productElement));
